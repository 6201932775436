import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./config/firebaseConfig";

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestPermission = () => {
    return Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Permissão concedida");

        return getToken(messaging, {
          vapidKey:
            "BL54RhjQIF_SfVb3C2pQ_8rOj4btwf3DiyWyMFMMCjOvTGh53SP4-29ml-rid6-0T0YB8qK6Nvh5LCdeUweop68",
        });
      } else {
        console.log("Permissão não concedida");
        return null;
      }
    }).catch((error) => {
      console.error("Erro ao solicitar permissão:", error);
      return null;
    });
  };

// messaging.onBackgroundMessage((payload) => {
//   console.log("received background message");

//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.notification.body,
//   };

//   self.registration.showNotification(notificationTitle,notificationOptions);
// });
