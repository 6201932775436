import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import RequireAuth from "./AuthRouter";
import Container from "./Container";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";
import Dashboard from "../components/Dashboard";
import ProfessionalsTable from "../components/tables/ProfessionalsTable";
import MyProfilePage from "../pages/MyProfilePage";
import CustomersTable from "../components/tables/CustomersTable";
import TrainingsTable from "../components/tables/Trainingstable";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <RequireAuth>
              <Container>
                <Outlet />
              </Container>
            </RequireAuth>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="training" element={<TrainingsTable />} />
          <Route path="customers" element={<CustomersTable />} />
          <Route path="professionals" element={<ProfessionalsTable />} />
          <Route path="profile" element={<MyProfilePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
