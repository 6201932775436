import React, { createContext } from "react";
import { useQuery } from "react-query";
import { USERS } from "../config/apiConfig";
import { getUserId } from "../utils/permissions";
import axios from "../config/axiosInstance";

const ApplicationContext = createContext();

const PermissionProvider = ({ children }) => {
  const { data: user } = useQuery(["user"], async () => {
    try {
      const userId = getUserId();
      if (!userId) throw new Error("");

      const { data } = await axios.get(`${USERS.GET}/${userId}`);

      return data.data;
    } catch (error) {
      return "USER";
    }
  });

  return (
    <ApplicationContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplication = () => React.useContext(ApplicationContext);

export { PermissionProvider, useApplication };
