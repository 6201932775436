import { useQuery } from "react-query";
import { COMPANIES } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import moment from "moment";
import { Accordion, Badge, Card } from "flowbite-react";
import { formatCurrencyBRL } from "../../utils/utils";
import DrawerLoading from "../DrawerLoading";

const CompanyDetailsForm = ({ company, isDrawerOpen, onClose }) => {
  const {
    isLoading: isLoadingCompanyDetails,
    errorCompanyDetails,
    data: companyDetails,
  } = useQuery(["companyDetails", company], async () => {
    if (company?.id) {
      const { data: items } = await axios.get(
        `${COMPANIES.GET_COMPANY_DETAILS}/${company?.id}`
      );
      return items.data;
    }
    return null;
  });

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 flex-wrap">
          <span className="font-semibold">Nome:</span>
          <span>{company?.name}</span>
        </div>
        <div className="flex gap-2 flex-wrap">
          <span className="font-semibold">Telefone:</span>
          <span>{company?.phoneNumber}</span>
        </div>
        <div className="flex gap-2 flex-wrap">
          <span className="font-semibold">Data de registro:</span>
          <span>{moment(company?.createdAt).format("DD/MM/YYYY")}</span>
        </div>
        <div className="flex gap-2 flex-wrap">
          <span className="font-semibold">Data de expiração:</span>
          <span>{moment(company?.expirationDate).format("DD/MM/YYYY")}</span>
        </div>
        <div className="flex gap-2 flex-wrap">
          <span className="font-semibold">Última atualização em:</span>
          <span>
            {moment(company?.updatedAt).format("DD/MM/YYYY | HH:mm")}h
          </span>
        </div>
        <div className="flex gap-2 flex-wrap">
          <span className="font-semibold">Plano:</span>
          <span>{company?.planId}</span>
        </div>
        <div className="flex gap-2 flex-wrap items-center">
          <span className="font-semibold">Qtd. Profissionais permitidos:</span>
          <span>{company?.numberOfUsers}</span>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
          <div className="flex flex-col gap-2 shadow border border-gray-100 rounded p-2 items-center">
            <span className="font-semibold">Qtd. agendamentos</span>
            <div className="flex gap-4">
              <div className="flex flex-col gap-1 shadow rounded p-2 items-center bg-gray-100">
                <div className="font-semibold text-sm">Pendentes</div>
                <Badge className="w-fit text-base" color="gray">
                  {companyDetails?.appointmentsCount?.find(
                    (appointment) => appointment.statusId === 0
                  )?.count || 0}
                </Badge>
              </div>
              <div className="flex flex-col gap-1 shadow rounded p-2 items-center bg-green-100">
                <div className="font-semibold text-sm">Confirmados</div>
                <Badge className="w-fit text-base" color="green">
                  {companyDetails?.appointmentsCount?.find(
                    (appointment) => appointment.statusId === 1
                  )?.count || 0}
                </Badge>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 shadow border border-gray-100 rounded p-2 items-center">
            <span className="font-semibold">Qtd. Comandas</span>
            <div className="flex gap-4">
              <div className="flex flex-col gap-1 shadow rounded p-2 items-center bg-gray-100">
                <div className="font-semibold text-sm">Pendentes</div>
                <Badge className="w-fit text-base" color="gray">
                  {companyDetails?.commandsCount?.find(
                    (command) => command.statusId === 0
                  )?.count || 0}
                </Badge>
              </div>
              <div className="flex flex-col gap-1 shadow rounded p-2 items-center bg-green-100">
                <div className="font-semibold text-sm">Confirmados</div>
                <Badge className="w-fit text-base" color="green">
                  {companyDetails?.commandsCount?.find(
                    (command) => command.statusId === 1
                  )?.count || 0}
                </Badge>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
          <div className="flex flex-col gap-2 shadow border border-gray-100 rounded p-2  items-center">
            <span className="font-semibold">Qtd. Serviços:</span>
            <div className="flex gap-4">
              <div className="flex flex-col gap-1 shadow rounded py-2 px-6 items-center bg-gray-100">
                <div className="font-semibold text-sm">Ativos</div>
                <Badge className="w-fit text-base" color="gray">
                  {companyDetails?.servicesCount}
                </Badge>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 shadow border border-gray-100 rounded p-2 items-center">
            <span className="font-semibold">Qtd. Produtos</span>
            <div className="flex gap-4">
              <div className="flex flex-col gap-1 shadow rounded py-2 px-6 items-center bg-gray-100">
                <div className="font-semibold text-sm">Ativos</div>
                <Badge className="w-fit text-base" color="gray">
                  {companyDetails?.productsCount}
                </Badge>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 shadow border border-gray-100 rounded p-2  items-center">
            <span className="font-semibold">Qtd. Categorias</span>
            <div className="flex gap-4">
              <div className="flex flex-col gap-1 shadow rounded py-2 px-6 items-center bg-gray-100">
                <div className="font-semibold text-sm">Ativos</div>
                <Badge className="w-fit text-base" color="gray">
                  {companyDetails?.categoriesCount}
                </Badge>
              </div>
            </div>
          </div>
        </div>
        <Accordion collapseAll>
          <Accordion.Panel>
            <Accordion.Title>
              Usuários ({companyDetails?.professionals.length})
            </Accordion.Title>
            {companyDetails?.professionals?.map((user, index) => (
              <Accordion.Content key={index}>
                <div>
                  <div>Nome: {user.name}</div>
                  <div>Email: {user.email}</div>
                  <div>Permissão: {user.permissionId}</div>
                </div>
              </Accordion.Content>
            ))}
          </Accordion.Panel>
        </Accordion>
      </div>
      {isLoadingCompanyDetails && <DrawerLoading />}
    </>
  );
};

export default CompanyDetailsForm;
