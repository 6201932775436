import Input from "../inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Checkbox, Label } from "flowbite-react";
import { useForm } from "react-hook-form";
import { COMPANIES } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import PhoneInput from "../inputs/PhoneInput";
import { resetValuesForm } from "../../utils/utils";
import { HiInformationCircle } from "react-icons/hi";
import SelectInput from "../inputs/SelectInput";

const CompanySupportForm = ({
  company,
  fetchCompanies,
  isDrawerOpen,
  onClose,
  type = "CREATE",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setKey] = useState(0);
  const [error, setError] = useState(null);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (type === "EDIT" && company) {
      Object.keys(company).forEach((key) => {
        setValue(key, company[key]);
      });
      setKey((prev) => prev + 1);
    } else if (type === "CREATE") {
      resetValuesForm(["phoneNumber"], setValue, reset);
    }
    setError(null);
  }, [company, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      if (type === "CREATE") {
        await axios.post(COMPANIES.GET_COMPANY, data);
      } else {
        await axios.put(`${COMPANIES.GET_COMPANY}/support/${company.id}`, data);
      }

      onClose(false);

      fetchCompanies();
      if (type === "CREATE") resetValuesForm(["phoneNumber"], setValue, reset);
      setError(null);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <Input
          type="text"
          label="Nome *"
          name="name"
          required={true}
          register={register}
          error={errors?.name && "Este campo é obrigatório"}
        />
      </div>
      <div className="mb-6">
        <PhoneInput
          type="text"
          label="Telefone *"
          name="phoneNumber"
          required={true}
          register={register}
          error={errors?.phoneNumber && "Este campo é obrigatório"}
        />
      </div>
      <div className="mb-6">
        <Input
          type="email"
          label="E-mail"
          name="email"
          required={true}
          register={register}
        />
      </div>
      <div className="mb-6">
        <div className="flex gap-4">
          <Input
            type="date"
            label="Data de expiração do plano"
            name="planExpirationDate"
            required={true}
            register={register}
          />
        </div>
      </div>

      <div className="mb-6">
        <Input
          type="number"
          label="Número de usuários *"
          name="numberOfUsers"
          min="1"
          required={true}
          register={register}
          error={errors?.numberOfUsers && "Este campo é obrigatório"}
        />
      </div>

      <div className="mb-6">
        <SelectInput
          label={`Plano *`}
          name="planId"
          required={true}
          register={register}
          error={errors?.planId}
        >
          <option value="FREE">Free</option>
          <option value="PRO">Pro</option>
        </SelectInput>
      </div>

      <div className="mb-6">
        <Input
          type="text"
          label="ID do usuário no ASAAS:"
          name="customer_id"
          required={false}
          register={register}
        />
      </div>

      <Button
        type="submit"
        className="w-full mb-48 md:mb-4 primary"
        isProcessing={isSubmitting}
        processingSpinner={
          <AiOutlineLoading className="h-6 w-6 animate-spin" />
        }
        disabled={isSubmitting}
      >
        {type === "CREATE" ? "Criar" : "Atualizar"} empresa
      </Button>
      {error && (
        <Alert color="failure" icon={HiInformationCircle}>
          Erro ao {error.config.method === "put" ? "atualizar" : "criar"}{" "}
          empresa
        </Alert>
      )}
    </form>
  );
};

export default CompanySupportForm;
