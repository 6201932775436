import { jwtDecode } from "jwt-decode";
import moment from "moment";

export const checkIfAuthenticated = () => {
  const token = localStorage.getItem("token");

  try {
    const decodedToken = jwtDecode(token);

    const tokenExpirationDate = new Date(decodedToken.exp) * 1000;
    const currentDate = new Date();

    return tokenExpirationDate > currentDate;
  } catch (error) {
    return false;
  }
};

export const saveUserInWindow = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  window.user = decodedToken;
};

export const convertMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHour = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes =
    remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`;

  return `${formattedHour}:${formattedMinutes}`;
};

export const calculateTimeService = () => {
  const options = [];
  const MAX_HOUR = 7;
  const maxTime = MAX_HOUR * 60;

  for (let i = 5; i <= maxTime; i += 5) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    let label = "";

    if (hours === 0) {
      label = `${minutes}m`;
    } else if (hours === 1 && minutes === 0) {
      label = "1h";
    } else if (hours === 1 && minutes !== 0) {
      label = `1h ${minutes}m`;
    } else {
      label = `${hours}h ${minutes}m`;
    }

    options.push({ value: i, label });
  }

  return options;
};

export const addMinutesToHours = (hour, minutesToAdd) => {
  let hoursParts = hour.split(":");
  let hourInt = parseInt(hoursParts[0]);
  let minutes = parseInt(hoursParts[1]);

  minutes += +minutesToAdd;

  hourInt += Math.floor(minutes / 60);
  minutes = minutes % 60;

  hourInt = hourInt % 24;

  var newHour =
    (hourInt < 10 ? "0" : "") +
    hourInt +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes;

  return newHour;
};

export const checkIfNewCustomer = (dateString) => {
  const date = moment(dateString);
  const differenceInDays = moment().diff(date, "days");

  //Considera-se 30 dias o cliente novo
  return differenceInDays < 30;
};

export const renderHourOptions = (interval) => {
  let hourOptions = [];

  for (let hour = 7; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const formattedHour = String(hour).padStart(2, "0");
      const formattedMinute = String(minute).padStart(2, "0");

      hourOptions.push(
        <option
          key={`${formattedHour}:${formattedMinute}`}
          value={`${formattedHour}:${formattedMinute}`}
        >
          {`${formattedHour}:${formattedMinute}`}
        </option>
      );
    }
  }
  return hourOptions;
};

export const resetValuesForm = (values, setValue, reset) => {
  values.forEach((value) => {
    setValue(value, "");
  });
  reset();
};

export const getFirstLettersName = (fullName) => {
  const nameParts = fullName.trim().split(" ");

  const firstLetterFirstName = nameParts[0][0];
  const firstLetterLastName =
    nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : "";

  return `${firstLetterFirstName}${firstLetterLastName}`.toUpperCase();
};

export const calculateCommission = (price, percentage) => {
  return (+price * +percentage) / 100;
};

export const calculateAverageInvoice = (total, startDate, endDate) => {
  const diffInMilliseconds = new Date(endDate) - new Date(startDate);
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  if (!diffInDays) return +total;

  return +total / diffInDays;
};

export const calculateAverageTicket = (total, totalCommands) => {
  if (!totalCommands) return 0;

  return +total / +totalCommands;
};

export const calculateExpirationPeriodTest = (date) => {
  const expirationDate = moment(date);
  const today = moment();
  return expirationDate.diff(today, "days") + 1;
};

export const formatCurrencyBRL = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});
