import React, { useEffect, useState } from "react";
import { COMPANIES } from "../../config/apiConfig";
import { Badge, Button, Card, Modal, Pagination, Table } from "flowbite-react";
import axios from "../../config/axiosInstance";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import { FiEye } from "react-icons/fi";
import Drawer from "../Drawer";
import CompanySupportForm from "../forms/CompanySupportForm";
import FilterToolbar from "../inputs/FilterToolbar";
import { FaEdit, FaSortAlphaDown } from "react-icons/fa";
import { FcPlanner } from "react-icons/fc";
import { GoPersonFill } from "react-icons/go";
import { IoIosCart, IoMdSearch } from "react-icons/io";
import CompanyDetailsForm from "../forms/CompanyDetailsForm";

moment.tz.setDefault("America/Sao_Paulo");
moment.locale("pt-br");

const CustomersTable = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [typeCustomer, setTypeCustomer] = useState(-1);
  const [planId, setPlanId] = useState("");
  const [expirationDate, setExpirationDate] = useState(0);
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDrawerOpenCompanyDetails, setIsDrawerOpenCompanyDetails] =
    useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const onPageChange = (page) => setCurrentPage(page);

  const {
    isLoading,
    error,
    data: companies,
  } = useQuery(
    ["companies", currentPage, typeCustomer, nameParam, planId, expirationDate],
    async () => {
      const { data: items } = await axios.get(
        `${COMPANIES.GET_COMPANIES}/support?page=${currentPage}&typeCustomer=${typeCustomer}&searchName=${searchName}&planId=${planId}&expirationDate=${expirationDate}`
      );
      setTotalPages(items.data.totalPages || 1);
      setTotalCount(items.data.totalCount || 0);

      return items.data.data;
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [nameParam]);

  const handleViewCompany = (company) => {
    setIsDrawerOpenCompanyDetails(true);
    setSelectedCompany(company);
  };

  const handleChangeCompany = (company) => {
    setIsDrawerOpen(true);
    setSelectedCompany(company);
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  return (
    <>
      <FilterToolbar
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
      >
        <div className="flex gap-2">
          <div className="flex gap-2 flex-col md:flex-row w-full">
            <div className="flex items-center gap-2 w-full bg-gray-100 rounded-md pl-2 pr-0 py-1">
              <IoIosCart className="color-secondary text-xl mt-1" />
              <select
                id="underline_select"
                className="mt-1 block py-1 px-0 w-full md:w-40 text-sm text-gray-600 bg-transparent border-0 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                value={planId}
                onChange={({ target }) => setPlanId(target.value)}
              >
                <option selected value="">
                  Todos os planos
                </option>
                <option value="FREE">Free</option>
                <option value="PRO">Pro</option>
              </select>
            </div>
            <div className="flex items-center gap-2 w-full bg-gray-100 rounded-md pl-2 pr-0 py-1">
              <FcPlanner className="color-secondary text-xl mt-1" />
              <select
                id="underline_select"
                className="mt-1 block py-1 px-0 w-full md:w-40 text-sm text-gray-600 bg-transparent border-0 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                value={expirationDate}
                onChange={({ target }) => setExpirationDate(target.value)}
              >
                <option selected value={0}>
                  Data de Expiração
                </option>
                <option value={1}>Não expirados</option>
                <option value={2}>Prestes a expirar</option>
                <option value={3}>Expirados</option>
              </select>
            </div>
          </div>
        </div>
      </FilterToolbar>
      <div className="mt-5 text-sm font-semibold">
        Total de {totalCount} clientes
      </div>
      <form
        className="flex gap-1 mt-2 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300 mb-4 md:mb-0"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome, email ou telefone..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>
      <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
        <Table striped>
          {companies?.length > 0 ? (
            <Table.Head>
              <Table.HeadCell className="text-center bg-gray-300">
                ID
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Nome
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Telefone
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Data de expiração
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Email
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Plano
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Ações
              </Table.HeadCell>
            </Table.Head>
          ) : (
            <></>
          )}

          <Table.Body className="divide-y">
            {!isLoading ? (
              <>
                {companies.length > 0 && (
                  <>
                    {companies.map((company, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                          #{company.id}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {company.name}
                        </Table.Cell>
                        <Table.Cell className="text-center whitespace-nowrap">
                          {company.phoneNumber}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                          <div>
                            {moment(company.planExpirationDate).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                          <div className="text-xs mt-1">
                            Expira em{" "}
                            <Badge className="w-fit inline" color="green">
                              {moment(company.planExpirationDate).diff(
                                moment(),
                                "days"
                              )}
                            </Badge>{" "}
                            dias
                          </div>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {company.email}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <Badge className="w-fit mx-auto" size="md">
                            {company.planId}
                          </Badge>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <div className="flex gap-1">
                            <Button
                              className="primary mx-auto"
                              onClick={() => handleViewCompany(company)}
                            >
                              <FiEye />
                            </Button>
                            <Button
                              className="bg-gray-500 mx-auto"
                              onClick={() => handleChangeCompany(company)}
                            >
                              <FaEdit />
                            </Button>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {Array.from({ length: 12 }).map((_, index) => (
                  <Table.Row
                    key={index}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={"Visualizar empresa"}
        onClose={setIsDrawerOpenCompanyDetails}
        isOpen={isDrawerOpenCompanyDetails}
        className="w-full md:w-3/6 pb-36"
      >
        <CompanyDetailsForm
          company={selectedCompany}
          onClose={setIsDrawerOpenCompanyDetails}
          isDrawerOpen={isDrawerOpenCompanyDetails}
        />
      </Drawer>

      <Drawer
        title={"Editar empresa"}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <CompanySupportForm
          company={selectedCompany}
          onClose={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          fetchCompanies={() =>
            queryClient.invalidateQueries(["companies", currentPage])
          }
          type="EDIT"
        />
      </Drawer>
    </>
  );
};

export default CustomersTable;
