import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { PROFESSIONALS } from "../../config/apiConfig";
import { Button, Pagination, Card, Badge, Table } from "flowbite-react";
import axios from "../../config/axiosInstance";
import ProfessionalForm from "../forms/ProfessionalForm";
import { useQuery, useQueryClient } from "react-query";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import FilterToolbar from "../inputs/FilterToolbar";
import { GoPersonFill } from "react-icons/go";
import { FiEye } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";

const ProfessionalsTable = () => {
  const queryClient = useQueryClient();
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [typeCustomer, setTypeCustomer] = useState(-1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("EDIT");
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: professionals,
  } = useQuery(["professionals", currentPage, nameParam], async () => {
    const { data: items } = await axios.get(
      `${PROFESSIONALS.GET_PROFESSIONALS}?page=${currentPage}&searchName=${nameParam}`
    );
    setTotalPages(items.data.totalPages || 1);
    setTotalCount(items.data.totalCount || 0);
    return items.data.data;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProfessional((prevProfessional) => ({
      ...prevProfessional,
      [name]: value,
    }));
  };

  const handleViewProfessional = async (professional) => {
    const { data: items } = await axios.get(
      `${PROFESSIONALS.GET_PROFESSIONAL}/${professional.id}`
    );

    const newTab = window.open(
      "https://app.relinq.com.br/login?token=" + items.data.token,
      "_blank",
      "incognito"
    );
  };

  const handleEditProfessional = (professional) => {
    setTypeDrawer("EDIT");
    setSelectedProfessional(professional);
  setIsDrawerOpen((prevState) => !prevState);
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
    setCurrentPage(1);
  };

  return (
    <>
      <FilterToolbar
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
      >
        <div className="flex gap-2">
          <div className="flex gap-2 flex-col md:flex-row w-full">
            <div className="flex items-center gap-2 w-full bg-gray-100 rounded-md pl-2 pr-0 py-1">
              <GoPersonFill className="color-secondary text-xl mt-1" />
              <select
                id="underline_select"
                className="mt-1 block py-1 px-0 w-full md:w-40 text-sm text-gray-600 bg-transparent border-0 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                value={typeCustomer}
                onChange={({ target }) => setTypeCustomer(target.value)}
              >
                <option selected value={-1}>
                  Todos os usuários
                </option>
                <option value={1}>Ativos</option>
                <option value={0}>Inativos</option>
              </select>
            </div>
          </div>
        </div>
      </FilterToolbar>
      <div className="mt-5 text-sm font-semibold">
        Total de {totalCount} usuários cadastrados
      </div>
      <form
        className="flex gap-1 mt-2 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300 mb-4 md:mb-0"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome, telefone ou email..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>
      <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
        <Table striped>
          {professionals?.length > 0 ? (
            <Table.Head>
              <Table.HeadCell className="text-center bg-gray-300">
                ID
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Nome
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Telefone
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Email
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Empresa
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Status
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Ações
              </Table.HeadCell>
            </Table.Head>
          ) : (
            <></>
          )}

          <Table.Body className="divide-y">
            {!isLoading ? (
              <>
                {professionals.length > 0 && (
                  <>
                    {professionals.map((professional, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                          #{professional.id}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {professional.name}
                        </Table.Cell>
                        <Table.Cell className="text-center whitespace-nowrap">
                          {professional.phoneNumber}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {professional.email}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {professional?.Company?.name}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {professional?.isActive ? (
                            <Badge color="success" className="w-fit">
                              Ativo
                            </Badge>
                          ) : (
                            <Badge color="pink" className="w-fit">
                              Inativo
                            </Badge>
                          )}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <div className="flex gap-2 justify-center">
                            <Button
                              className="primary"
                              onClick={() =>
                                handleViewProfessional(professional)
                              }
                            >
                              <FiEye />
                            </Button>
                            <Button
                              className="bg-gray-500"
                              onClick={() =>
                                handleEditProfessional(professional)
                              }
                            >
                              <FaEdit />
                            </Button>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {Array.from({ length: 12 }).map((_, index) => (
                  <Table.Row
                    key={index}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                      <div
                        role="status"
                        className="max-w-sm animate-pulse inline"
                      >
                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title="Editar profissional"
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <ProfessionalForm
          professional={selectedProfessional}
          handleInputChange={handleInputChange}
          fetchProfessionals={() =>
            queryClient.invalidateQueries(["professionals", currentPage])
          }
          onClose={setIsDrawerOpen}
          isOpen={isDrawerOpen}
          type={typeDrawer}
        />
      </Drawer>
    </>
  );
};

export default ProfessionalsTable;
